<template>
  <b-row>
    <b-col
      md="6"
      class="align-self-center"
    >
      <div class="d-flex">
        <h5 class="align-self-end font-weight-bolder">
          Información de la pieza
        </h5>
      </div>
    </b-col>

    <!-- #region begin::Arrival date select -->
    <b-col
      md="6"
      class="mb-1"
    >
      <DatePickerWithValidation
        v-model="arriveDate"
        vid="arriveDate"
        rules="required"
        label="Fecha de llegada"
        name="fecha de llegada"
        placeholder="Selecciona la fecha de llegada"
      />
    </b-col>
    <!-- #endregion end::Arrival date select -->

    <!-- #region begin::Piece tag input -->
    <b-col md="3">
      <TextInputWithValidation
        v-model="tag"
        vid="tag"
        rules="required"
        type="text"
        label="Tag"
        name="tag"
        maxlength="250"
        placeholder="Tag"
      />
    </b-col>
    <!-- #endregion end::Piece tag input -->

    <!-- #region begin::Piece serie number input -->
    <b-col md="3">
      <TextInputWithValidation
        v-model="serieNumber"
        vid="serieNumber"
        rules="required"
        type="text"
        label="No. Serie"
        name="no. serie"
        maxlength="250"
        placeholder="No. Serie"
      />
    </b-col>
    <!-- #endregion end::Piece serie number input -->

    <!-- #region begin::Piece petition number input -->
    <b-col md="3">
      <TextInputWithValidation
        v-model="petitionNumber"
        vid="petitionNumber"
        rules="required"
        type="text"
        label="No. Pedimento"
        name="no. pedimento"
        maxlength="250"
        placeholder="No. Pedimento"
      />
    </b-col>
    <!-- #endregion end::Piece petition number input -->

    <!-- #region begin::Piece invoice number input -->
    <b-col md="3">
      <TextInputWithValidation
        v-model="invoiceNumber"
        vid="invoiceNumber"
        rules="required"
        type="text"
        label="No. Factura"
        name="no. factura"
        maxlength="250"
        placeholder="No. Factura"
      />
    </b-col>
    <!-- #endregion end::Piece invoice number input -->

    <!-- #region begin::Piece import number input -->
    <b-col md="3">
      <TextInputWithValidation
        v-model="importNumber"
        vid="importNumber"
        rules="required"
        type="text"
        label="No. Importación"
        name="no. importación"
        maxlength="250"
        placeholder="No. Importación"
      />
    </b-col>
    <!-- #endregion end::Piece import number input -->

    <!-- #region begin::Piece condition select -->
    <b-col md="3">
      <SelectWithValidation
        v-model="selectedCondition"
        vid="condition"
        rules="required"
        name="condición"
        label="Condición"
        property="text"
        placeholder="Condición"
        :disabled="disableSelectCondition()"
        :options="disableSelectCondition() ? getConditions : getConditions.slice(1)"
      />
    </b-col>
    <!-- #endregion end::Piece condition select -->

    <!-- #region begin::Piece availability select -->
    <b-col md="3">
      <SelectWithValidation
        v-model="selectedAvailability"
        vid="availability"
        rules="required"
        name="disponibilidad"
        label="Disponibilidad"
        property="text"
        placeholder="Disponibilidad"
        :options="getAvailabilities"
      />
    </b-col>
    <!-- #endregion end::Piece availability select -->

    <!-- #region begin::Piece location select -->
    <b-col md="3">
      <SelectWithValidation
        v-model="selectedLocation"
        vid="location"
        rules="required"
        name="ubicación"
        label="Ubicación"
        property="text"
        placeholder="Ubicación"
        :disabled="true"
        :options="getLocations"
      />
    </b-col>
    <!-- #endregion end::Piece location select -->

  </b-row>
</template>

<script>
// #region Imports
import { mapGetters } from 'vuex'
import { required, alphaNum } from '@validations'
import { extend } from 'vee-validate'
import {
  BRow, BCol, VBTooltip,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    SelectWithValidation,
    TextInputWithValidation,
    DatePickerWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    piece: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      id: '',
      tag: '',
      arriveDate: '',
      serieNumber: '',
      importNumber: '',
      invoiceNumber: '',
      petitionNumber: '',
      selectedLocation: '',
      selectedCondition: '',
      selectedAvailability: '',
      // * 14/09/2022 - TAG: Validations
      alphaNum,
      required,
    }
  },
  computed: {
    ...mapGetters({
      // #region Store getters to fill selectables form components
      getLocations: 'pieces/getLocations',
      getConditions: 'pieces/getConditions',
      getAvailabilities: 'pieces/getAvailabilities',
      // #endregion
      getSelectedType: 'products/getSelectedType',
      getRealCount: 'products/getRealCount',
    }),
    selectedType: {
      get() { return this.getSelectedType },
    },
    realCount: {
      get() { return this.getRealCount },
    },
  },
  watch: {
    selectedType(newValue, oldValue) {
      if ((newValue.text === 'Usado' && oldValue.text === 'Remanufacturado')
          || (newValue.text === 'Remanufacturado' && oldValue.text === 'Usado')) {
        return
      }

      if (newValue.text === 'Nuevo') {
        this.selectedCondition = {
          text: 'Nuevo',
        }
      } else {
        this.selectedCondition = {
          text: 'Habilitado',
        }
      }
    },
  },
  created() {
    if (this.piece.IdPiece) {
      this.loadExistingPiece()
    }

    extend('alpha_num', {
      ...alphaNum,
      message: 'El campo solo debe contender letras y números',
    })
  },
  mounted() {
    if (this.disableSelectCondition()) {
      this.selectedCondition = {
        text: 'Nuevo',
      }
    } else {
      this.selectedCondition = {
        text: 'Habilitado',
      }
    }
  },
  methods: {
    disableSelectCondition() {
      if (this.piece.productType === 'Nuevo') {
        return true
      }

      return false
    },
    loadExistingPiece() {
      this.tag = this.piece.Tag
      this.id = this.piece.IdPiece
      this.arriveDate = this.piece.ArriveDate
      this.serieNumber = this.piece.NoSeries
      this.importNumber = this.piece.NoImport
      this.invoiceNumber = this.piece.NoInvoice
      this.petitionNumber = this.piece.NoPetition

      const location = this.getLocations.find(element => element.text === this.piece.Location)
      this.selectedLocation = location

      const condition = this.getConditions.find(element => element.text === this.piece.Condition)
      this.selectedCondition = condition

      const availability = this.getAvailabilities.find(element => element.text === this.piece.Availability)
      this.selectedAvailability = availability
    },
  },
}
</script>
