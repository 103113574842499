<template>
  <b-modal
    id="edit-piece-modal"
    ref="edit-piece-modal"
    centered
    size="lg"
    title="Editar pieza"
    :no-close-on-backdrop="true"
    @hidden="onCloseModal"
  >

    <!-- #region::Validation form observer -->
    <validation-observer ref="savePieceModal">
      <b-form @submit="$event.preventDefault()">

        <!-- #region::Modal content -->
        <b-card-text>
          <SavePieceForm
            ref="savePieceForm"
            :piece-number="1"
            :piece="piece"
          />
        </b-card-text>
        <!-- #endregion::Modal content -->

      </b-form>
    </validation-observer>
    <!-- #endregion::Validation form observer -->

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        @click="onUpdatePiece"
      >
        <span class="align-middle">Guardar cambios</span>
      </b-button>
    </template>
    <!-- #endregion::Footer -->
  </b-modal>
</template>

<script>
import {
  ValidationObserver, localize,
} from 'vee-validate'
import {
  BButton, BModal, VBModal, BCardText, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import SavePieceForm from '@/modules/store/incomes/components/pieces/SavePieceForm.vue'

export default {
  components: {
    BForm,
    BModal,
    BButton,
    BCardText,
    SavePieceForm,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    updatePiece: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      piece: null,
    }
  },
  created() {
    localize('es')
  },
  methods: {
    showModal(piece) {
      this.piece = piece
      this.$refs['edit-piece-modal'].show()
    },
    hideModal() {
      this.$refs['edit-piece-modal'].hide()
    },
    async onUpdatePiece() {
      const success = await this.$refs.savePieceModal.validate()

      if (success) {
        this.updatePiece(this.$refs.savePieceForm)
      }
    },
    onCloseModal() {
      this.$emit('on-close-modal')
    },
  },
}
</script>
