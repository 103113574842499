<template>
  <div>

    <!-- #region::Table -->
    <vue-good-table
      ref="pieces-table"
      class="vgt-table-checkbox-styled"
      :columns="columns"
      :rows="piecesList"
      style-class="vgt-table condensed vgt-checkbox-col"
      :line-numbers="true"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdown: [3, 5, 10],
        dropdownAllowAll: true,
        jumpFirstOrLast : true,
        firstLabel : 'Primera página',
        lastLabel : 'Última página',
        nextLabel: 'Siguiente',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Piezas por página',
        ofLabel: 'de',
        allLabel: 'Todo',
      }"
    >

      <!-- #region::Empty response -->
      <div slot="emptystate">
        No hay piezas registradas
      </div>
      <!-- #endregion::Empty response -->

      <!-- #region::Column headers -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          <h6 class="capitalize-col-hd"><strong>{{ props.column.label }}</strong></h6>
        </span>
      </template>
      <!-- #endregion::Column headers -->

      <!-- #region::Modifying rows cells -->
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- #region::Tag cell -->
        <span v-if="props.column.field === 'Tag'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Tag }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Tag cell -->

        <!-- #region::NoSeries cell -->
        <span v-else-if="props.column.field === 'NoSeries'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoSeries }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoSeries cell -->

        <!-- #region::NoPetition cell -->
        <span v-else-if="props.column.field === 'NoPetition'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoPetition }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoPetition cell -->

        <!-- #region::NoInvoice cell -->
        <span v-else-if="props.column.field === 'NoInvoice'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoInvoice }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoInvoice cell -->

        <!-- #region::NoImport cell -->
        <span v-else-if="props.column.field === 'NoImport'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoImport }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoImport cell -->

        <!-- #region::Condition cell -->
        <span v-else-if="props.column.field === 'Condition'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Condition }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Condition cell -->

        <!-- #region::Availability cell -->
        <span v-else-if="props.column.field === 'Availability'">
          <h6><b-badge
            class="ava-label"
            :variant="getAvailabilityStyle(props.row.Availability)"
          >
            <text-highlight
              :queries="canHighlight"
              highlight-style="background-color: #ebd834;"
            >{{ props.row.Availability }}
            </text-highlight></b-badge></h6>
        </span>
        <!-- #endregion::Availability cell -->

        <!-- #region::Location cell -->
        <span v-else-if="props.column.field === 'Location'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Location }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Location cell -->

        <!-- #region::Arrive date cell -->
        <span v-else-if="props.column.field === 'ArriveDate'">
          <h6 class="ava-label">
            <text-highlight
              :queries="canHighlight"
              highlight-style="background-color: #ebd834;"
            >
              {{ props.row.ArriveDate | moment("DD/MM/YYYY") }}
            </text-highlight></h6>
        </span>
        <!-- #endregion::Arrive date cell -->

        <!-- #region::Status label -->
        <span v-else-if="props.column.field === 'StatusText'">
          <h6><text-highlight
            :queries="canHighlight"
            class="sm-banner-alert text-nowrap"
            :class="statusColorStyle(props.row.StatusText)"
            role="alert"
            style="display: inline; font-size: 11px;"
          >
            {{ props.row.StatusText }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Status label -->

        <!-- #region::Order id -->
        <span v-else-if="props.column.field === 'ID'">
          <router-link
            :to="modelRounte(props.row)"
            target="_blank"
          >
            <h6 class="text-primary"><text-highlight
              :queries="canHighlight"
              highlight-style="background-color: #ebd834;"
            >
              {{ props.row.ID }}
            </text-highlight></h6>
          </router-link>
        </span>
        <!-- #endregion::Order id -->

        <!-- #region::Actions column -->
        <span v-else-if="props.column.field === 'actions'">

          <!-- #region::Edit piece button -->
          <b-button
            id="editPieceButton"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="onOpenEditPieceModal(props.row)"
          >
            <feather-icon
              icon="Edit3Icon"
            />
          </b-button>
          <b-tooltip
            target="editPieceButton"
            placement="top"
            triggers="hover"
          >
            Editar
          </b-tooltip>
          <!-- #endregion::Edit piece button -->

          <!-- #region::Delete piece button -->
          <b-button
            id="deletePieceButton"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            :disabled="!canDeletePiece(props.row)"
            @click="onOpenDeletingPieceConfirmation(props.row)"
          >
            <feather-icon
              icon="Trash2Icon"
            />
          </b-button>
          <b-tooltip
            target="deletePieceButton"
            placement="top"
            triggers="hover"
          >
            Eliminar
          </b-tooltip>
          <!-- #endregion::Delete piece button -->

        </span>
        <!-- #endregion::Actions column -->

        <!-- #region::Rest of columns -->
        <span v-else>
          <h6>{{ props.formattedRow[props.column.field] }}</h6>
        </span>
        <!-- #endregion::Rest of columns -->

      </template>
      <!-- #endregion::Modifying rows cells -->

    </vue-good-table>
    <!-- #endregion::Table -->

    <EditPieceModal
      ref="editPieceModal"
      :update-piece="onUpdatePiece"
    />

  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import {
  VBTooltip, BBadge, BButton, BTooltip,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import TextHighlight from 'vue-text-highlight'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import EditPieceModal from '@/modules/store/incomes/components/modals/EditPieceModal.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  name: 'PiecesList',
  components: {
    BBadge,
    BButton,
    BTooltip,
    VueGoodTable,
    TextHighlight,
    EditPieceModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    pieces: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: null,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    // * Conditional columns
    showInvoiceColumn: {
      type: Boolean,
      default: false,
    },
    showActionsButton: {
      type: Boolean,
      default: false,
    },
    // * highlight text by seracher
    highlightText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      columns: [
        {
          field: 'Tag',
          label: 'Tag',
          hidden: !this.$ability.can('read', 'Piece_tag'),
        },
        {
          field: 'NoSeries',
          label: 'No. serie',
          hidden: !this.$ability.can('read', 'Piece_serie'),
        },
        {
          field: 'NoPetition',
          label: 'No. Pedimento',
          hidden: !this.$ability.can('read', 'Piece_petition'),
        },
        {
          field: 'NoInvoice',
          label: 'No. factura',
          hidden: !this.showInvoiceColumn
            || !this.$ability.can('read', 'Piece_invoice'),
        },
        {
          field: 'NoImport',
          label: 'No. importación',
          hidden: !this.$ability.can('read', 'Piece_import'),
        },
        {
          field: 'Condition',
          label: 'Condición',
          hidden: !this.$ability.can('read', 'Piece_condition'),
        },
        {
          field: 'Availability',
          label: 'Disponibilidad',
          hidden: !this.$ability.can('read', 'Piece_availability'),
        },
        {
          field: 'Location',
          label: 'Ubicación',
          hidden: !this.$ability.can('read', 'Piece_location'),
        },
        {
          field: 'ArriveDate',
          label: 'Fecha de llegada',
          hidden: !this.$ability.can('read', 'Piece_arrival_date'),
        },
        {
          field: 'StatusText',
          label: 'Estatus',
          hidden: !JSON.parse(localStorage.userData).Role === 'Administrador',
        },
        {
          field: 'ID',
          label: 'ID',
          hidden: !JSON.parse(localStorage.userData).Role === 'Administrador',
        },
        {
          field: 'actions',
          label: 'Acciones',
          hidden: !this.$ability.can('edit pieces', 'Inventory_entries'),
        },
      ],
      piecesList: this.pieces,
      selectedPiece: null,
    }
  },
  computed: {
    ...mapGetters({
      getSearchTerms: 'filters/getSearchTerms',
    }),
    searchTerms: {
      get() { return this.getSearchTerms },
    },
    canHighlight() {
      return this.highlightText ? this.searchTerms : []
    },
    isAdmin() {
      return this.userData.Role === 'Administrador'
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('rendering', false)
    })
  },
  methods: {
    ...mapActions({
      updatePiece: 'incomes/updatePiece',
      deletePiece: 'incomes/deletePiece',
    }),
    getAvailabilityStyle(availability) {
      switch (availability) {
        case 'Disponible':
          return 'light-success'
        case 'No disponible':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },
    statusColorStyle(status) {
      switch (status) {
        case 'Inventario':
          return 'alert-green'
        case 'Circuito':
          return 'alert-purple'
        case 'Orden':
          return 'alert-yellow'
        case 'Entregado':
          return 'alert-blue'
        case 'Solicitud':
          return 'alert-gray'
        case 'Eliminada':
          return 'alert-orange'
        default:
          return 'alert-blue'
      }
    },
    modelRounte(row) {
      const rounte = row.StatusText === 'Solicitud' ? 'quote-details' : 'order-details'
      return { name: rounte, params: { id: row.ID } }
    },
    onOpenEditPieceModal(piece) {
      this.selectedPiece = piece
      this.$set(this.selectedPiece, 'productType', this.product.Type)
      this.$refs.editPieceModal.showModal(piece)
    },
    onOpenDeletingPieceConfirmation(piece) {
      this.$swal({
        title: '¿Desea eliminar la pieza?',
        text: 'Esta acción no se podrá deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.onDeletePiece(piece)
        }
      })
    },
    async onUpdatePiece(piece) {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        formData.append('id', piece.id)
        formData.append('tag', piece.tag)
        formData.append('noSeries', piece.serieNumber)
        formData.append('arriveDate', piece.arriveDate)
        formData.append('noImport', piece.importNumber)
        formData.append('noInvoice', piece.invoiceNumber)
        formData.append('noPetition', piece.petitionNumber)
        formData.append('condition', piece.selectedCondition.text)
        formData.append('availability', piece.selectedAvailability.text)

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        const response = await this.updatePiece(formData)

        if (this.selectedPiece) {
          this.selectedPiece.Tag = response.data.data.Tag
          this.selectedPiece.NoSeries = response.data.data.NoSeries
          this.selectedPiece.ArriveDate = response.data.data.ArriveDate
          this.selectedPiece.NoImport = response.data.data.NoImport
          this.selectedPiece.NoInvoice = response.data.data.NoInvoice
          this.selectedPiece.Condition = response.data.data.Condition
          this.selectedPiece.NoPetition = response.data.data.NoPetition
          this.selectedPiece.Availability = response.data.data.Availability
        }

        this.showToast(
          'Pieza actualizada',
          'La información de la pieza ha sido actualizada correctamente',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$refs.editPieceModal.hideModal()
        this.selectedPiece = null
        this.$swal.close()
      }
    },
    async onDeletePiece(piece) {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        await this.deletePiece(piece.IdPiece)

        this.showToast(
          'Pieza eliminada',
          'La pieza ha sido eliminada correctamente',
          'success',
        )

        this.piecesList = this.piecesList.filter((p) => p.IdPiece !== piece.IdPiece)
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },
    canDeletePiece(piece) {
      return this.$ability.can('delete piece', 'Inventory_entry') && piece.StatusText === 'Eliminada'
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
